<template>
    <div>
        <div class="d-flex justify-center" style="margin-top: 5px;">
            <v-chip outlined>{{period}}</v-chip>
        </div>
        <component :is="component" style="height: 250px;" :value="tableData"></component>
        <v-divider v-if="cType === 'component'"></v-divider>
        <v-container grid-list-md class="padding-20" v-if="cType === 'component'">
            <v-layout row wrap>
                <v-chip outlined><span style="color:#43A047">TOTAL: + {{formattedTableTotal}}</span></v-chip>
                <v-spacer></v-spacer>
                <v-chip outlined @click="$router.push({ path: '/dashboard/widgetSeeMoreContainer',query: { 
                   name: 'income', 
                  } })">SHOW MORE</v-chip>
            </v-layout>
        </v-container>
    </div>
</template>
<script>

    import { formatMoney } from '../../../javascript/globalFunctions'
    import { dateManager } from '../../../javascript/dateManager'
    import { widgetManager } from '../../../javascript/widgetManager'

    export default {
        data() {
            return {
                component: null,
                cType: '',
                tableData: [],
                tableTotal: 0,
                formattedTableTotal: '',
                period: ''
            }
        },
        methods: {
            formatAccountAmount() {
                this.formattedTableTotal = formatMoney(this.tableTotal, 2, ".", ",")
            },
            showComponent: function () {
                if (this.tableData.length !== 0) {
                    this.component = () => import('../../charts/donutChart.vue')
                    this.cType = "component"
                } else {
                    this.component = () => import('../noDataWidget.vue')
                    this.cType = ""
                }
            },
            returnPeriod() {
                this.period = dateManager.returnPeriodRange(
                    this.$store.state.Settings
                    , new Date())
            }
        },
        beforeMount() {
            this.returnPeriod()

            let table = widgetManager.buildIncomeCategoryTotals(this.period)
            this.tableData = table.tableData
            this.tableTotal = table.tableTotal

            this.showComponent()

            this.formatAccountAmount()
            
        }
    }
</script>


<style>

    .red {
        color: #F44336;
    }
</style>